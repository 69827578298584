import styled from "styled-components";

export const OverlayContainer = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    width: 200px;
    border: 1px solid #d3d3d3;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    border-radius: 7px;
    margin-top: 20px;
    padding: 10px;
    z-index: 10;
`;

export const FilterContainerForStatus = styled.span`
position: relative;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 0.5rem;
`;